<template>
  <PageCard
    pageName="Edit Profile Card"
    :iconClass="['fa', 'fa-users', 'fa-lg']"
  >
    <template slot="page_content">
      <form>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="inputName4">Name</label>
            <input
              type="name"
              class="form-control"
              id="inputName4"
              placeholder="Name"
              v-model="profileCard.name"
            />
          </div>
          <div class="form-group col-md-6">
            <label for="inputDescription4">Description</label>
            <input
              type="description"
              class="form-control"
              id="inputDescription4"
              placeholder="Description"
              v-model="profileCard.description"
            />
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="inputCardRow">Card Row</label>
            <input
              type="text"
              class="form-control"
              id="inputCardRow"
              v-model="profileCard.card_row"
            />
          </div>
          <div class="form-group col-md-6">
            <label for="inputCardColumn">CardColumn</label>
            <input
              type="text"
              class="form-control"
              id="inputCardColumn"
              v-model="profileCard.card_column"
            />
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="inputCardSize">CardSize</label>
            <input
              type="text"
              class="form-control"
              id="inputCardSize"
              v-model="profileCard.card_size"
            />
          </div>
          <div class="form-group col-md-6">
            <label for="inputParentId">Parent Id</label>
            <input
              type="text"
              class="form-control"
              id="inputParentId"
              v-model="profileCard.parent_id"
            />
          </div>
        </div>
        <div class="form-group">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="cardEnable"
              v-model="profileCard.enabled"
            />
            <label class="form-check-label" for="gridCheck">
              Enable
            </label>
          </div>
        </div>
      </form>
      <div slot="footer">
        <b-row class>
          <b-col md="12">
            <router-link
              tag="button"
              :to="{ path: '/super_admin/profile_cards' }"
              class="btn btn-secondary fw-btn mt-3 mr-3 white-text"
              >Cancel</router-link
            >
            <b-button
              type="submit"
              variant="primary"
              class="btn btn-primary fw-btn mt-3 mx-3"
              @click="editCard"
              >Edit Profile Card</b-button
            >
          </b-col>
        </b-row>
      </div>
    </template>
  </PageCard>
</template>

<script>
import PageCard from '@/modules/core/components/layouts/PageCard'
import Vue from 'vue'
import router from '@/router'

export default {
  name: 'EditProfileCard',
  data() {
    return {
      profileCard: {
        id: '',
        name: '',
        description: '',
        card_row: '',
        card_column: '',
        card_size: '',
        enabled: true,
        parent_id: 0
      }
    }
  },
  components: {
    PageCard
  },
  created() {
    let vm = this
    this.$http
      .get('/api/super_admin/profile_cards/' + this.$route.params.id, {
        handleErrors: true,
        params: { super_admin: true }
      })
      .then(res => {
        vm.profileCard = res.data
      })
  },
  methods: {
    editCard() {
      this.$http
        .put(
          '/api/super_admin/profile_cards/' + this.$route.params.id,
          this.profileCard,
          {
            handleErrors: true
          }
        )
        .then(res => {
          Vue.toasted.show(res.data.status, {
            icon: 'user-circle',
            type: 'success'
          })
          router.push('/super_admin/profile_cards')
        })
    }
  }
}
</script>
